console.log("front_end.js");
//import {$,jQuery} from './front_end/jquery-1.12.4.min';
//require('./front_end/jquery-1.12.4.min').start()

require('./front_end/jquery.flexslider')
require('./front_end/jquery.scrollTo')
require('./front_end/bootstrap')
require('./front_end/bootstrap-hover-dropdown.min')
require('./front_end/back-to-top')
require('./front_end/home')
require('./front_end/main')
require('./front_end/JsBarcode.all.min')
$(document).ready(function() {
    
  /* ======= Header Background Slideshow - Flexslider ======= */    
  /* Ref: https://github.com/woothemes/FlexSlider/wiki/FlexSlider-Properties */
  
  $('.hero-slider').flexslider({
      animation: "fade",
      directionNav: false, //remove the default direction-nav - https://github.com/woothemes/FlexSlider/wiki/FlexSlider-Properties
      controlNav: false, //remove the default control-nav
      slideshowSpeed: 8000,
      start: function(){
           $(this).find('.slide').css("display", "block"); //prevent flash of the images
      },
  });     
  
  
  /* ======= Play/Stop YouTube/Vimeo Video in Bootstrpa Modal ======= */

  $('.play-trigger').on('click', function() {
      
      var theModal = $(this).data("target");
      var theVideo = $(theModal + ' iframe').attr('src');
      var theVideoAuto = theVideo + "&amp;autoplay=1";
      
      $(theModal).on('shown.bs.modal', function () {
          $(theModal + ' iframe').attr('src', theVideoAuto);
      });
      
      $(theModal).on('hide.bs.modal', function () {
          $(theModal + ' iframe').attr('src', '');
      });
      
      $(theModal).on('hidden.bs.modal', function () {
          $(theModal + ' iframe').attr('src', theVideo);
      });

  });
  

});

